body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --error-color: #e74c3c;
  --background: linear-gradient(215.01deg, #F13A43 8.44%, #991D1B 90.59%);
  --text-color: #333;
  --text-purple: #503d66;
  --modal-background: white;
  --modal-overlay: rgba(0, 0, 0, 0.5);
  --pink-contentoh: #E33AA9;
  --white-contentoh: #fff;
  --red-light-contentoh: #FF7373;
  --red-contentoh: #B64545;
  --gray-contentoh: #707070;
  --pink-hard-contentoh:#B12D84;
  --purple-light-contentoh:#817393;
  --gray-light: #F0F0F0;
  --blue-light:#F7F7FC;
  --purple:#8A6CAA;
  --gray-medium:#CBCBCB;
  --black:#262626;
  --gray-light-secondary: #E2E2E2;
  --green:#85BC5B;
  --yellow: #ECDD1D;
  --disabled: #ddd;
}
/*Equivalencias de colores con components library*/
/* --purple-light-contentoh -  S4
 --pink-hard-contentoh -  original_magenta
 --pink-contentoh  -  magenta_s2  */