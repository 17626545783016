@import "./fonts.css";

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body.sb-main-padded.sb-show-main {
  padding: 0;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.sb-main-padded.sb-show-main #root {
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #f0eef2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #d4d1d7;
}
