@font-face {
  font-family: "Avenir Next";
  src: url("../assets/fonts/avenirnext/AvenirNextLTPro-Regular.otf");
}

@font-face {
  font-family: "Lato";
  src: url("../assets/fonts/lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Raleway";
  src: url("../assets/fonts/raleway/Raleway-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Raleway-500";
  src: url("../assets/fonts/raleway/Raleway-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Raleway-600";
  src: url("../assets/fonts/raleway/Raleway-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Raleway-700";
  src: url("../assets/fonts/raleway/Raleway-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Raleway-800";
  src: url("../assets/fonts/raleway/Raleway-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Raleway-900";
  src: url("../assets/fonts/raleway/Raleway-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "RobotoMedium";
  src: url("../assets/fonts/roboto/Roboto-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "RobotoRegular";
  src: url("../assets/fonts/roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/roboto/Roboto-Regular.ttf");
  font-weight: 400;
}

.prueba {
  color: #9e3e26;
  color: #b42983;
  color: #e7e7e7;
}
